<template>
  <div class="grid formgrid p-fluid">
    <PrinterDialog v-model="printerDialogVisible" :url="printer_url" ref="printer" :printers="printers" :payload="printer_data" :type="printer_type" />

    <OverlayPanel ref="detail">
        <BasicDatatable  :rows="details" 
        :headers="headers" 
        :headerVisible="false" 
        :footerVisible="false"
        :rowsFilter="false"
        :expansionKey="'id'"
        :rowsExpandable="'extra'" 
        :headersExpandable="extraHeaders"
        rowaction expandable>
        <template #custom-row-button="{ slotScope }">
            <Button :loading="slotScope.loading" label="TERMINAR" @click="finishItem(slotScope)"></Button>
        </template>
    </BasicDatatable>
    </OverlayPanel>
    <PaymentSidebar 
    :deliveryArea="deliveryArea"
    :paymentTypes="paymentTypes" 
    :ticket="selectedTicket"
    v-model="paymentVisible"
    @hide="paymentVisible = false" 
    @pay="addPayment" 
    @edit="editPayment"
    @saveDelivery="saveDelivery" 
    @saveShipping="saveShipping"
    @delete="deletePayment" 
    @save="savePayment" />
    <Loader v-model="loading" />
      <div class="col-12">
        <Card>
            <template #title>
                Pedidos
            </template>
            <template #content>
                <div class="grid formgrid p-fluid">
                    <div class="col-12 field">
                        <table class="pedidos" style="width: 100%; border-spacing: 0 6px; font-size: large; text-align: left;">
                        <tr>
                            <th>Orden</th>
                            <th>Inicio</th>
                            <th>Tiempo</th>
                            <th>Tipo</th>
                            <th style="width: 20%">Estatus</th>
                            <th>Total</th>
                            <th style="width: 20%"></th>
                        </tr>
                        <tr @click="openDetail($event, ticket)" class="body" v-for="ticket in tickets" :key="ticket.id">
                            <td>
                                #{{ticket.id_number}} <span v-if="ticket.identifier"> - {{ticket.identifier}}</span>
                            </td>
                            <td>{{formatDate(ticket.date, 'time')}}</td>
                            <td>{{ticket.minutos}} minutos</td>
                            <td>{{getTicketType(ticket)}}</td>
                            <td>
                                <span v-if="ticket.is_paid">💵 PAGADO</span>
                                <ProgressBar :class="ticket.progressClass" :value="ticket.progressValue">
                                    {{ticket.progressText}}
                                </ProgressBar>
                            </td>
                            <td>{{formatCurrency(ticket.total)}}</td>
                            <td>
                                <div class="grid p-fluid">
                                    <div v-if="!ticket.is_paid" class="col-12" style="margin-bottom:.2rem; padding-top: .2rem;">
                                        <Button :loading="ticket.loading" label="Pagar" icon="pi pi-money-bill" @click="$event.preventDefault(); openPayment(ticket)"/> 
                                    </div>
                                    <div class="col-6">
                                        <Button :loading="ticket.loading" label="Imprimir" icon="pi pi-print" @click="print($event)" />
                                    </div>
                                    <div class="col-6">
                                        <Button :loading="ticket.loading" label="Terminar" icon="pi pi-send" @click="finish(ticket)"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </table>
                    </div>
                </div>
            </template>
        </Card>
      </div>
    </div>
</template>

<script>
require("../../utilities/DATE_UTILITIES")
import formatDate from "../../utilities/formatDate";
import { DateTime } from "luxon";
import PrinterDialog from '../../components/PrinterDialog.vue'
import BasicDatatable from '../../components/general/BasicDatatable.vue'
import Loader from '../../components/general/Loader.vue'
import FormMixin from '../../mixins/form.js'
import { ticket } from '../../models/ticket'
import formatCurrency from '../../utilities/formatCurrency'
import { fillObject, HeaderGrid } from '../../utilities/General'
import { basicData } from "../../data/basicData";
import PaymentSidebar from '../../components/PaymentSidebar.vue'
import { printer_payload } from '../../models/printer_payload'

export default {
    components: {Loader, PrinterDialog, BasicDatatable, PaymentSidebar},
    mixins: [FormMixin],
    data() {
        return  {
            printers: [],
            printer_type: 'payment',
            printer_url: null,
            printer_data: {},
            printerDialogVisible: false,
            paymentTypes: [],
            paymentVisible: false,
            loading: false,
            selectedTicket: new ticket(),
            tickets: [],
            details: [],
            deliveryArea: [],
            extraHeaders: [ 
                new HeaderGrid('Paquete', 'description'),
                new HeaderGrid('Nombre', 'name'),
            ],
            headers: [
                new HeaderGrid('Cantidad', 'quantity'),
                new HeaderGrid('Nombre','name'),
                new HeaderGrid('Nota','note')
            ],
        }
    },
    methods: {
        print(event) {
            //TODO: Elaborar funcion de impresion
            console.log('printing...',  event);
        },
        formatDate: formatDate,
        formatCurrency: formatCurrency,
        getTicketType(ticket) {
            if (ticket.id_service_type == 1){
                return "MESA";
            }else if (ticket.id_service_type == 2){
                return "MOSTRADOR"
            }else if (ticket.id_service_type == 3){
                return "DOMICILIO"
            }
        },
        saveDelivery(payload) {
            this.selectedTicket.id_delivery_area = payload; 
        },
        saveShipping(payload) {
            this.selectedTicket.shipping = payload;
        },
        async finishItem(item) {
            try {
                item.loading = true;
                let updatedItem =  await new ticket(this.session).prepareFinishItem(item.id);
                item = fillObject(item, updatedItem);
                this.showSuccess("Producto terminado con exito");
            } catch (error) {
                this.showError(error);
            } finally {
                item.loading = false;
            }
        },
        deletePayment(payment) { 
            let id_number = payment.id_number;
            this.selectedTicket.payments = this.selectedTicket.payments.filter(x => x.id_number != payment.id_number);
            this.selectedTicket.payments.forEach(x => {
                if (x.id_number > id_number) 
                x.id_number = x.id_number - 1;
            });
        }, 
        async savePayment(response) {
            try {
                this.selectedTicket.is_invoiceable = false;
                if (response != null) 
                    this.selectedTicket.is_invoiceable = response.event == "invoice";
                
                this.paymentVisible = false;
                this.loading = true;
                let newTicket = await this.selectedTicket.pay();
                this.selectedTicket = fillObject(this.selectedTicket, newTicket);

                let index = this.tickets.findIndex(x => x.id == this.selectedTicket.id);
                if (index >= 0)
                {
                this.tickets[index].is_paid = true;
                }
                //Imprimimos el ticket
                let printer_data = new printer_payload();
                printer_data.name = this.selectedTicket.branch_name;
                printer_data.taxInfo = this.selectedTicket.branch_tax_info;
                printer_data.address = this.selectedTicket.branch_address;
                printer_data.email = this.selectedTicket.branch_email;
                printer_data.date = this.selectedTicket.date;
                printer_data.id = this.selectedTicket.id_number.toString();
                printer_data.footer = "Gracias por su compra!";
                printer_data.phoneNumber = this.selectedTicket.branch_phone_number;
                printer_data.subtotal = this.selectedTicket.subtotal;
                printer_data.total = this.selectedTicket.total;
                printer_data.taxes = this.selectedTicket.iva;
                if (this.selectedTicket.id_customer) {
                let customer = this.customers.find(x => x.id == this.selectedTicket.id_customer);
                printer_data.customerInfo.address = customer.address;
                printer_data.customerInfo.email = customer.email;
                printer_data.customerInfo.name = customer.name;
                printer_data.customerInfo.phoneNumber = customer.phone;
                printer_data.customerInfo.taxInfo = customer.rfc;
                }
                this.selectedTicket.items.forEach(item => {
                printer_data.products.push({
                    quantity: item.quantity, 
                    description: item.name, 
                    sku: item.key_name, 
                    price: item.unit_price, 
                    total: item.total
                });
                });
                this.selectedTicket.payments.forEach(payment => {
                printer_data.change = printer_data.change + payment.amount;
                printer_data.payments.push({
                    name: payment.name,
                    amount: payment.amount
                });
                });
                printer_data.change = printer_data.change - this.selectedTicket.total;
                printer_data.openCashDrawer = true;
                this.printer_data = printer_data;
                this.printer_type = "payment";
                this.selectedTicket.is_paid = true;
                if (this.selectedTicket.is_paid && this.selectedTicket.prepare_status == 3) {
                    this.tickets = this.tickets.filter(x => x.id == this.selectedTicket.id);
                }

                let cashier_printer = this.printers.find(x => x.is_cashier_default == true);
                if (cashier_printer) {
                    try {
                        await this.$refs.printer.print(cashier_printer, printer_data, this.$config.printer_url, "payment");
                    } catch (error) {
                        console.log(error);
                        this.printerDialogVisible = true;
                    }
                }  else {
                    this.printerDialogVisible = true;
                }

            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        addPayment(payment) {
            payment.id_number = this.selectedTicket.payments.length + 1;
            this.selectedTicket.payments.push(payment);
        },
        editPayment(payment) {
            let index = this.selectedTicket.payments.findIndex(x => x.id_number == payment.id_number);
            this.selectedTicket.payments[index] = payment;
        },
        openDetail(event,ticket) {
            if (event.target.tagName == "BUTTON" || event.target.parentElement == "BUTTON")
                return;
            this.details = ticket.items;
            this.$refs.detail.toggle(event);
        },
        openPayment(myTicket)
        {
            this.selectedTicket = fillObject(this.selectedTicket, myTicket);
            this.selectedTicket.id_company = this.session.id_company;
            this.selectedTicket.id_branch = this.session.id_branch;
            this.selectedTicket.user = this.session.user;
            this.selectedTicket.token = this.session.token;
            this.paymentVisible = true;
        },
        async finish(myTicket) {
            myTicket.loading = true;
            try {
                let newTicket = await new ticket(this.session).prepareFinish(myTicket.id);
                myTicket = fillObject(myTicket, newTicket);
                this.tickets.forEach(ticket => {
                    var end = DateTime.fromISO(new Date().getUTCJSON());
                var start = DateTime.fromISO(ticket.date);
                var diffInMonths = end.diff(start, 'minutes');
                ticket.minutos = Math.round(diffInMonths.toObject().minutes);
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });

                if (myTicket.prepare_status == 3 && myTicket.is_paid) {
                    this.tickets = this.tickets.filter(x => x.id != myTicket.id);
                }

            } catch (error) {
                this.showError(error);
            } finally {
                myTicket.loading = false;
            }
        }
    },
    async mounted() {
        this.loading = true;
        try {
            this.printer_url = this.$config.printer_url;
            this.ticket = new ticket(this.session);
            this.printers = await new basicData(this.session).getPrinters();
            this.paymentTypes = await new basicData(this.session).getPaymentTypes();
            this.deliveryArea = await new basicData(this.session).getDeliveryArea();
            this.tickets = await new ticket(this.session).pending();
            this.tickets.forEach(ticket => {
                var end = DateTime.fromISO(new Date().getUTCJSON());
                var start = DateTime.fromISO(ticket.date);
                var diffInMonths = end.diff(start, 'minutes');
                ticket.minutos = Math.round(diffInMonths.toObject().minutes);

                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });
            
            let vueData = this;
            this.lifeInterval = setInterval(function () {
                //MODIFICAMOS EL ARRAY
                vueData.tickets.forEach(ticket => {
                    var end = DateTime.fromISO(new Date().getUTCJSON());
                    var start = DateTime.fromISO(ticket.date);
                    var diffInMonths = end.diff(start, 'minutes');
                    ticket.minutos = Math.round(diffInMonths.toObject().minutes);
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });
                console.log('ACTUALIZAR TICKETS', new Date())
            }, 30000);

            this.lifeInterval2 = setInterval(async function () {
                vueData.tickets = await new ticket(vueData.session).pending();
                vueData.tickets.forEach(ticket => {
                    var end = DateTime.fromISO(new Date().getUTCJSON());
                    var start = DateTime.fromISO(ticket.date);
                    var diffInMonths = end.diff(start, 'minutes');
                    ticket.minutos = Math.round(diffInMonths.toObject().minutes);
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });
                console.log('RECARGA TICKETS', new Date())
            }, 100000);
        } catch (error) {
            this.showError(error);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>

.p-listbox-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.p-listbox-list-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

/* Handle */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.progress-early .p-progressbar-value {
    background-color: #33a151;
}
.progress-ok .p-progressbar-value {
    background-color: yellow;
}
.progress-need .p-progressbar-value {
    background-color: orange;
}
.progress-out .p-progressbar-value {
    background-color: red;
}

.pedidos td {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-collapse: collapse;
}

.pedidos tr.body {
    cursor: pointer;
    opacity: .82;
}

.pedidos tr.body:hover {
    opacity: 1;
}


</style>